import React from 'react';
import i18n from "../../../translations/i18n";
import { Helmet } from "react-helmet";
import {OBJECTS_PATH} from "../../../App";

export default class Maintenance extends React.Component {

    constructor(props) {
        super(props);
    }

    footerLoc() {
        let localized = i18n.t('footer')
        if (localized && localized !== "footer") document.getElementById('footerText').innerHTML = localized;
    }

    render() {
        this.footerLoc();
        return (
            <>
                <Helmet><title>Maintenance</title></Helmet>
                <div className="o-headers">
                    <img id="img" src={OBJECTS_PATH.PICTURES.MAINTENANCE_ICON} alt="Maintenance"/>
                    <h1 className="h h-1">Website currently under maintenance</h1>
                    <br/>
                    <h1 className="h h-1" dangerouslySetInnerHTML={{__html: this.props.maintenanceSubText}}/>
                </div>
            </>
        )
    }
}