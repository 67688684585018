import React from 'react';
import i18n from '../../../translations/i18n.js';
import {Redirect} from "react-router-dom";
import {parseLanguage, DEFAULT_LANGUAGE} from "../../../translations/i18n";
import {Helmet} from "react-helmet";
import {LANGUAGE_URL_PARAM, OBJECTS_PATH} from "../../../App";
import LocalizationService from "../../../translations/configLangContext";
// const REDIRECT_PAGES = {
//     DOCTOR: "doctor"
// }

export default class SignupLanding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            windowSize: "",
            actionForm: "#"
        }
        this.source = null;
        this.subLanPlaceHolder = null;
        this.subLangOptions = [];
        this.subLanForced = null;
        this.translateCompleted = false;
    }

    async componentDidMount() {
        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.parsedLanguage.CULTURE_CODE, "signup_landing");

        window.setNiceSelect();
        document.querySelectorAll("#sublanguages + .nice-select li.option").forEach(el => {
            el.addEventListener("click", event => {
                this.subLanguageEventHandler(event.target.getAttribute("data-value"));
            });
        });

        this.setState({ translateCompleted: true});

    }

    componentDidUpdate() {
        window.setNiceSelect();
        document.querySelectorAll("#sublanguages + .nice-select li.option").forEach(el => {
            el.addEventListener("click", event => {
                this.subLanguageEventHandler(event.target.getAttribute("data-value"))
            })
        });
    }

    cardClickHandler(path) {
        if(path.includes(LANGUAGE_URL_PARAM)) {
            if(this.subLanForced) path = path.replace(LANGUAGE_URL_PARAM, "");
            else path = path.replace(LANGUAGE_URL_PARAM, this.parsedLanguage.LAN_CODE);
        } else {
            if(!this.subLanForced) path += "/" + this.parsedLanguage.LAN_CODE;
        }
        const urlParams = new URLSearchParams(window.location.search);
        path += `?${urlParams}`;
        //redirect target will be pushed to path; it will be null if not found
        this.props.history.push(path, {target: this.target, source: this.source});
    };

    subLanguageMenuRender() {
        if(!this.props.subLanguagesInfo) return;
        this.props.subLanguagesInfo.forEach(subLanInfo => {
            let optObj = {};
            optObj.label = subLanInfo.label;
            optObj.key = subLanInfo.sub_culture;
            if(optObj.key === this.parsedLanguage.CULTURE_CODE) {
                this.subLanPlaceHolder = optObj.label;
            }
            this.subLangOptions.push(optObj);
        });
    }

    subLanguageDefaultMan() {
        let defaultSubLan;
        for(let subLanInfo of this.props.subLanguagesInfo) {
            if(subLanInfo.isDefaultSubLan) defaultSubLan = subLanInfo
        }
        if(this.parsedLanguage && defaultSubLan.sub_culture
            && this.parsedLanguage.CULTURE_CODE !== defaultSubLan.sub_culture)
            this.subLanguageEventHandler(defaultSubLan.sub_culture)
    }

    subLanguageEventHandler(subLanKey) {
        // to avoid all reload-related issues we use 'sublan' param workaround
        let redirectUrl = new URL(window.location)
        redirectUrl.searchParams.set('sublan', subLanKey);
        window.location = redirectUrl;
    }

    footerLoc() {
        let localized = i18n.t('footer')
        if (localized) document.getElementById('footerText').innerHTML = localized;
    }

    headerRender() {
        return(
            <Helmet>
                <title>{i18n.t('signupLanding.tabTitle')}</title>
                <meta name="description" content={i18n.t('signupLanding.pageTitle')} />
            </Helmet>
        );
    }

    render() {
        const isValidUrl = urlString=> {
            var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
                '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
            return !!urlPattern.test(urlString);
        }
        //search for redirect target in URL parameters
        let targetUrl = new URL(window.location.href).searchParams.get('target');
        if(isValidUrl(targetUrl)) {
            this.target = targetUrl;
        }

        this.source = new URL(window.location.href).searchParams.get('source');

        this.subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(this.subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(this.subLanForced);
        }
        else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        if(!this.parsedLanguage) {
            // language not recognized, fallback and redirect to default one
            let localizedPath = this.props.match.path.replace(":lan?", DEFAULT_LANGUAGE.LAN_CODE);
            return <Redirect to={localizedPath} />
        }

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        if (this.props.subLanguagesInfo && !this.subLanForced) this.subLanguageDefaultMan();
        this.subLanguageMenuRender();

        this.footerLoc();

        return(
            <form >
                {this.headerRender()}
                <div className="o-headers">
                    <h1 className="h h-1">{i18n.t('signupLanding.pageTitle')}</h1>
                </div>
                <div className="o-prose">
                    <div className="e-content">
                        <p>{i18n.t('signupLanding.pageMessage')}</p>
                    </div>
                </div>

                <div className="validation-summary-valid">
                    <ul>
                        <li style={{display:"none"}}></li>
                    </ul>
                </div>{/* /.validation-summary-valid */}

                <fieldset className="c-fieldset">
                    {/* NOTE: nascosto language selector */}
                    {this.props.subLanguagesInfo ?
                        <div className="form-row">
                            <div className="form-group">
                                {/* LOCALIZE: */}
                                <label htmlFor="sublanguages"
                                       className="c-label">{i18n.t("professional.choose_your_country")}</label>
                                <select id="sublanguages" name="sublanguages"
                                        className="c-input c-input-select">{/* removed onChange={(event) => { this.subLanguageEventHandler(event.key) }} */}
                                    {this.subLangOptions.map((opt) => {
                                        if (opt.label === this.subLanPlaceHolder) return <option value={opt.key}
                                                                                                 selected>{opt.label}</option>
                                        else return <option value={opt.key}>{opt.label}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        : ""
                    }

                    <div className="u-wrap-fields">
                        <legend className="c-legend">{i18n.t('signupLanding.options')}</legend>
                        {/* test con bottone, nell'ultima voce esempio di codice usando il link semplice  */}
                        <div className="form-row">
                            <div className="form-group form-group-buttons pt-0">
                                {this.props.doctorPath ?
                                    <div className="o-button o-button-full pb-3">
                                        <button type="button"
                                            className="c-button c-button-secondary c-button-twi"
                                            onClick={() => this.cardClickHandler(this.props.doctorPath)}>
                                            <span>{i18n.t('signupLanding.option0')}</span>
                                            <i className="i"><img src="/images/icon_arrow_right.svg" alt="arrow right" /></i>
                                        </button>
                                    </div>/* /o-button */
                                    : ""
                                }

                                {this.props.nursesPath ?
                                    <div className="o-button o-button-full pb-3">
                                        <button type="button"
                                                className="c-button c-button-secondary c-button-twi"
                                                onClick={() => this.cardClickHandler(this.props.nursesPath)}>
                                            <span>{i18n.t('signupLanding.option3')}</span>
                                            <i className="i"><img src="/images/icon_arrow_right.svg" alt="arrow right"/></i>
                                        </button>
                                    </div>/* /o-button */
                                    : ""
                                }

                                {this.props.pharmacistPath ?
                                    <div className="o-button o-button-full pb-3">
                                        <button type="button"
                                            className="c-button c-button-secondary c-button-twi"
                                            onClick={() => this.cardClickHandler(this.props.pharmacistPath)}>
                                            <span>{i18n.t('signupLanding.option1')}</span>
                                            <i className="i"><img src="/images/icon_arrow_right.svg" alt="arrow right"/></i>
                                        </button>
                                    </div>/* /o-button */
                                    : ""
                                }

                                {this.props.userPath ?
                                    <div className="o-button o-button-full pb-3">
                                        <button type="button"
                                            className="c-button c-button-secondary c-button-twi"
                                            onClick={() => this.cardClickHandler(this.props.userPath)}>
                                            <span>{i18n.t('signupLanding.option2')}</span>
                                            <i className="i"><img src="/images/icon_arrow_right.svg" alt="arrow right"/></i>
                                        </button>
                                    </div>/* /o-button */
                                    : ""
                                }
                            </div>{/* /.form-group */}
                        </div> {/* /.form-row */}
                    </div>{/* /.u-wrap-fields */}
                </fieldset>{/* /.c-fieldset */}
            </form>
        );
    }
}
