import React from "react";
import i18n from "../../../translations/i18n";
import {parseLanguage, DEFAULT_LANGUAGE} from "../../../translations/i18n";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {OBJECTS_PATH} from '../../../App.js';
import LocalizationService from "../../../translations/configLangContext";
const LAMBDA_STATUS = { OK: "OK", ERROR: "ERROR", USER_NOT_FOUND: "USER_NOT_FOUND" }

export default class Unsubscribe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.subLanForced = null;
        this.translateCompleted = false;
    }

    async componentDidMount() {
        this.requestConsentDisable();

        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.parsedLanguage.CULTURE_CODE, "unsubscribe");

        this.setState({ translateCompleted: true});

    }

    footerLoc() {
        let localized = i18n.t('footer')
        if (localized && i18n.exists('footer')) document.getElementById('footerText').innerHTML = localized;
    }

    requestConsentDisable() {
        let currUrl = new URL(window.location.href);
        let params = new URLSearchParams(currUrl.search);
        let consentParam = params.get("consent");
        let email = params.get("email");
        let channelParam = params.get("channel");
        let ppuSource = params.get("uns_source");

        let url = this.props.serviceUrl
        let values = {
            consentToResetName: consentParam,
            usrFilterValue: email,
            usrFilterName: "email",
            ppuSource: ppuSource,
            channelParam: channelParam
        }
        let data = JSON.stringify(values, null, 2);

        fetch(url, {
            credentials: 'same-origin', // 'include', default: 'omit'
            method: 'POST',             // 'GET', 'PUT', 'DELETE', etc.
            body: data,
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        })
            .then(response =>{
                if (!response.ok) {
                    this.setState({ resStatus : LAMBDA_STATUS.ERROR });
                    console.error("ERROR:", response);
                    return null;
                }
                return response;
            })
            .then(res => {
                if(!res) return null;
                this.setState({ resStatus : LAMBDA_STATUS.OK });
            })
            .catch(error => {
                console.log("errore", error);
            });
    }

    subLanguageDefaultMan() {
        let defaultSubLan;
        for(let subLanInfo of this.props.subLanguagesInfo) {
            if(subLanInfo.isDefaultSubLan) defaultSubLan = subLanInfo
        }
        if(this.parsedLanguage && defaultSubLan.sub_culture && this.parsedLanguage.CULTURE_CODE !== defaultSubLan.sub_culture) this.subLanguageEventHandler(defaultSubLan.sub_culture)
    }

    subLanguageEventHandler(subLanKey) {
        // to avoid all reload-related issues we use 'sublan' param workaround
        let redirectUrl = new URL(window.location)
        redirectUrl.searchParams.set('sublan', subLanKey);
        window.location = redirectUrl;
    }

    render() {
        this.subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(this.subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(this.subLanForced);
        }
        else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        if(!this.parsedLanguage) {
            // language not recognized, fallback and redirect to default one
            let localizedPath = this.props.match.path.replace(":lan?", DEFAULT_LANGUAGE.LAN_CODE);
            return <Redirect to={localizedPath} />
        }

        if(this.props.subLanguagesInfo && !this.subLanForced) this.subLanguageDefaultMan();

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        this.footerLoc();

        switch (this.state.resStatus) {
            case LAMBDA_STATUS.ERROR: {
                return (
                    <div>
                        <img id="img" src={OBJECTS_PATH.PICTURES.GENERIC_ERROR_ICON} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }} alt="error icon"/>
                        <h3 className="text-center mb-4 mt-4">{i18n.t('econsent.serverError')}</h3>
                    </div>
                );
            }
            case LAMBDA_STATUS.OK: {
                return(
                    <div>
                        <h2 id="confirmMessage" dangerouslySetInnerHTML={{__html: i18n.t('unsubscribe.confirmMessage')}} />
                    </div>
                )
            }
            default: {
                return (
                    <div>
                        <Helmet>
                            <title>{i18n.t('unsubscribe.pageTitle')}</title>
                            <meta name="description" content={i18n.t('unsubscribe.pageTitle')} />
                        </Helmet>
                        <img id="img" alt="Loading" src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/>
                    </div>
                )
            }
        }
    }
};
