import React from 'react';
import i18n from "../../../translations/i18n";
import {Helmet} from "react-helmet";
import {OBJECTS_PATH} from "../../../App";

export default class PageNotFound extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    footerLoc() {
        let localized = i18n.t('footer')
        if (localized) document.getElementById('footerText').innerHTML = localized;
    }

    render() {
        this.footerLoc();
        return (
            <>
                <Helmet>
                    <title>404</title>
                    <meta name="description" content="404" />
                </Helmet>
                <div className="o-headers">
                    <div class="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.GENERIC_ERROR_ICON} alt="Error 404" width="130px" height="130px" />
                    </div>
                    <h1 className="h h-1">404 Page Not Found</h1>
                </div>
            </>
        )
    }
}