import React from "react";
import i18n from "../../../translations/i18n";
import {parseLanguage, DEFAULT_LANGUAGE} from "../../../translations/i18n";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {OBJECTS_PATH} from '../../../App.js';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import LocalizationService from "../../../translations/configLangContext";
const BACKEND_STATUS = { OK: "OK", ERROR: "ERROR", USER_NOT_FOUND: "USER_NOT_FOUND", WARNING: "WARN" }

export default class EventPlatformConsents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.subLanForced = null;
        this.eventPlatformIdParam = null;
        this.individualEidParam = null;
        this.aidCodeParam = null;
        this.errorCode = "";
        this.infoEventPlatoformFetched = null;
        this.redirectPath = null;
        this.change_pass_token = null;
        this.apiWarningMessage = null;
        this.state = {
            timer: 5,  //set countdown timer before redirect
            prefCenterUrl: null
        }
        this.countdown = 0;
        this.eventRedirectError = null;
        this.translateCompleted = false;
    }

    async componentDidMount() {
        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.parsedLanguage.CULTURE_CODE, "event_platform_consents");

        this.setState({ translateCompleted: true});
    }

    componentDidUpdate(){
        //stop countdown when timer reaches 0, then redirect to target
        if(this.state.timer === 0) {
            clearInterval(this.countdown);
            window.location.replace(this.eventRedirectError);
        }
    }

    eventPlatformFetchConsents() {
        let bodyCloudRun = {reqType: "fetchConsents", aid_code: this.aidCodeParam, individualEid: this.individualEidParam, eventid: this.eventPlatformIdParam}
        if(!this.state.resStatus) this.eventPlatformCloudRunFetch(bodyCloudRun);
    }

    eventPlatformCloudRunFetch(body) {
        fetch(this.props.serviceUrl, {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify(body),
            headers: new Headers({'Content-Type': 'application/json'})
        }).then(response => {
            return response.json();
        }).then(data => {
            if(data.error) {
                console.error(`Event Backend returned an error: ${data.message}`);
                this.errorCode = data.errorCode;
                this.setState({ resStatus : BACKEND_STATUS.ERROR });
                return null;
            }
            this.infoEventPlatoformFetched = data;
            this.setState({ resStatus : BACKEND_STATUS.OK });
        });
    }

    validationSchemaBuild(){
        let validationSchema = {};
        for(let consent of this.infoEventPlatoformFetched.message.consents) {
            if(consent.isRequired) {
                validationSchema[consent.id] = Yup.boolean().oneOf([true], i18n.t("econsent.form.campoObbligatorio"));
            }
        }
        return validationSchema;
    }

    initialValuesBuild() {
        let validationValuesObj = {}
        for(let consent of this.infoEventPlatoformFetched.message.consents) {
            validationValuesObj[consent.id] = false;
        }
        return validationValuesObj;
    }

    eventConsentManager(errors, touched) {
        let elemToRenderArr = [];
        for(let consent of this.infoEventPlatoformFetched.message.consents) {
            let elemToRender =
                <div className="form-check">
                    <label className="c-label" htmlFor={consent.id}>
                        <Field type="checkbox" name={consent.id} id={consent.id}
                               className={'form-check-input c-checkbox' + (errors[consent.id] && touched[consent.id] ? ' is-invalid' : '')} />
                        <span className="c-label-text" dangerouslySetInnerHTML={{__html: consent.description}} />
                        <ErrorMessage name={consent.id} component="div" className="invalid-feedback"/>
                    </label>
                </div>
            elemToRenderArr.push(elemToRender);
        }
        return (
            <div className="form-row">
                <div className="form-group form-group-opts">
                    <ol className="list list-opt">
                        <li className='item'>
                            {elemToRenderArr}
                        </li>
                    </ol>
                </div>
            </div>
        );
    }

    footerLoc() {
        let localized = i18n.t('footer')
        if (localized) document.getElementById('footerText').innerHTML = localized;
    }

    subLanguageDefaultMan() {
        let defaultSubLan;
        for(let subLanInfo of this.props.subLanguagesInfo) {
            if(subLanInfo.isDefaultSubLan) defaultSubLan = subLanInfo
        }
        if(this.parsedLanguage && defaultSubLan.sub_culture && this.parsedLanguage.CULTURE_CODE !== defaultSubLan.sub_culture) this.subLanguageEventHandler(defaultSubLan.sub_culture)
    }

    subLanguageEventHandler(subLanKey) {
        // to avoid all reload-related issues we use 'sublan' param workaround
        let redirectUrl = new URL(window.location)
        redirectUrl.searchParams.set('sublan', subLanKey);
        window.location = redirectUrl;
    }

    submitConsentsManager(values) {
        let body = {};
        body.reqType = "sendConsents";
        body.consents = values;
        body.aid_code = this.aidCodeParam;
        body.individualEid = this.individualEidParam;
        body.eventid = this.eventPlatformIdParam;
        fetch(this.props.serviceUrl, {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify(body),
            headers: new Headers({'Content-Type': 'application/json'})
        }).then(response => {
            return response.json();
        }).then(data => {
            if(data.error) {
                console.error(`ERROR: ${data.message}`);
                this.errorCode = data.errorCode;
                this.setState({ resStatus : BACKEND_STATUS.ERROR });
                return null;
            }
            if(data.api_managed_error) {
                this.apiWarningMessage = data.api_message;
                this.eventRedirectError = data.event_error_redirect;
                this.setState({ resStatus : BACKEND_STATUS.WARNING });
                return null;
            }
            this.manageRedirectUrl(data.message.redirectPath, data.message.change_pass_token);
        });
    }

    manageRedirectUrl(redirectInfo, changePassToken) {
        let redirectPath;
        if(redirectInfo === "SET_PASS_PAGE") {
            if(!this.props.domainInfo.changepass_path || this.props.domainInfo.changepass_path.length <= 0) {
                console.error(`ERROR: No Change Password page found`);
                this.errorCode = "EP112";
                this.setState({ resStatus : BACKEND_STATUS.ERROR });
                return;
            }
            redirectPath = this.props.domainInfo.changepass_path;
        } else {
            if(!this.props.domainInfo.success_landing_path || this.props.domainInfo.success_landing_path.length <= 0) {
                console.error(`ERROR: No Success page found`);
                this.errorCode = "EP111";
                this.setState({ resStatus : BACKEND_STATUS.ERROR });
                return;
            }
            redirectPath = this.props.domainInfo.success_landing_path;
        }
        let redirectBaseUrlToParse = new URL(window.location.origin);
        if(redirectBaseUrlToParse.toString().endsWith("/")) redirectBaseUrlToParse = redirectBaseUrlToParse.toString().substring(0, redirectBaseUrlToParse.toString().length - 1);
        redirectBaseUrlToParse += redirectPath;
        let successUrlFixed = new URL(redirectBaseUrlToParse);
        if(this.aidCode) successUrlFixed.searchParams.append('aidcode', this.aidCode);
        if(this.individualEidParam) successUrlFixed.searchParams.append('eid', this.individualEidParam);
        if(changePassToken) successUrlFixed.searchParams.append('token', changePassToken);
        window.location.replace(successUrlFixed);
    }

    render() {
        this.subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(this.subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(this.subLanForced);
        }
        else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        if(!this.parsedLanguage) {
            // language not recognized, fallback and redirect to default one
            let localizedPath = this.props.match.path.replace(":lan?", DEFAULT_LANGUAGE.LAN_CODE);
            return <Redirect to={localizedPath} />
        }
        if(this.props.subLanguagesInfo && !this.subLanForced) this.subLanguageDefaultMan();

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        this.footerLoc();

        if(this.props.location && this.props.location.state && this.props.location.state.eventId) this.eventPlatformIdParam = this.props.location.state.eventId;        // aidCode provided by registration form
        else this.eventPlatformIdParam = new URL(window.location.href).searchParams.get('eventid');
        if(this.props.location && this.props.location.state && this.props.location.state.aidCode) this.aidCodeParam = this.props.location.state.aidCode;      // aidCode provided by registration form
        else this.aidCodeParam = new URL(window.location.href).searchParams.get('aidcode');
        this.individualEidParam = new URL(window.location.href).searchParams.get('eid');

        this.eventPlatformFetchConsents();

        switch (this.state.resStatus) {
            case BACKEND_STATUS.ERROR: {
                return (
                    <div>
                        <img id="img" src={OBJECTS_PATH.PICTURES.GENERIC_ERROR_ICON} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }} alt="error icon"/>
                        <h3 className="text-center mb-4 mt-4">{i18n.t('econsent.serverError')}</h3>
                        {this.errorCode ?
                            <h4 className="text-center mb-5 mt-5" style={{ fontSize: 15, marginBottom: "2%" }}>Error Code: {this.errorCode}</h4>
                            : ""
                        }
                    </div>
                );
            }
            case BACKEND_STATUS.WARNING: {
                this.countdown = setInterval(()=>{this.setState({timer: this.state.timer -1})}, 3000);
                return(
                    <div>
                        <div className="o-headers">
                            <h1 className="h h-1" id='regTitle'>{this.infoEventPlatoformFetched.message.registrationTitle}</h1>
                        </div>
                        {/*<h4 className="text-center mb-5 mt-5" style={{ fontSize: 15, marginBottom: "2%" }}>Error Code: {this.errorCode}</h4>*/}
                        {this.apiWarningMessage ?
                            <h3 className="text-center mb-4 mt-4">{this.apiWarningMessage}</h3>
                            : ""
                        }
                        {this.eventRedirectError ?
                            <div className="o-prose">
                                <div className="e-content">
                                    <p className="f-2" dangerouslySetInnerHTML={
                                        {__html: i18n.t('professional.succesPageRedirect', {time: `${this.state.timer}`, link: `<a class="link-break" href="${this.eventRedirectError}">${this.eventRedirectError}</a>`, link_text: `${this.eventRedirectError}`})}
                                    } />
                                </div>
                            </div>
                            : ""
                        }
                    </div>
                )
            }
            case BACKEND_STATUS.OK: {
                let initValues = this.initialValuesBuild();
                return(
                    <Formik initialValues={initValues}
                            validationSchema={Yup.object().shape(this.validationSchemaBuild())}
                            onSubmit={(values, { setSubmitting } ) => { this.submitConsentsManager(values) } }>
                        {({errors, status, touched, isSubmitting, handleSubmit}) => (<>
                            <Form>
                                <div className="o-headers">
                                    <h1 className="h h-1" id='regTitle'>{this.infoEventPlatoformFetched.message.registrationTitle}</h1>
                                </div>

                                <div className="o-prose">
                                    <div className="e-content">
                                        <p>{this.infoEventPlatoformFetched.message.registrationDescription}</p>
                                    </div>
                                </div>

                                { this.eventConsentManager(errors, touched) }

                                <div className="o-prose">
                                    <div className="e-content">
                                        <p>{this.infoEventPlatoformFetched.message.registrationDisclaimer}</p>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group form-group-buttons">
                                        <div className="o-button o-button-full">
                                            <Field type="submit" name="submit_btn" id="submit_btn" className="c-button c-button-primary txt-uppercase" value={
                                                this.infoEventPlatoformFetched.message.registrationConfirmButtonTitle ?
                                                    this.infoEventPlatoformFetched.message.registrationConfirmButtonTitle :
                                                    i18n.t('professional.submitBtn')
                                            }/>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </>)}
                    </Formik>

                )
            }
            default: {
                return (
                    <div>
                        <Helmet>
                            {!i18n.t('eventPlatformConsents.pageTitle').includes("eventPlatformConsents.pageTitle") ?
                                <title>{i18n.t('eventPlatformConsents.pageTitle')}</title>
                                : "AiD"
                            }
                            {!i18n.t('eventPlatformConsents.pageTitle').includes("eventPlatformConsents.pageTitle") ?
                                <meta name="description" content={i18n.t('eventPlatformConsents.pageTitle')} />
                                : "AiD"
                            }
                        </Helmet>
                        <img id="img" alt="Loading" src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/>
                    </div>
                )
            }
        }
    }
};
