import React from 'react';
import i18n, {DEFAULT_LANGUAGE, parseLanguage} from "../../../translations/i18n";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import {ERROR_CODES, OBJECTS_PATH} from "../../../App";
import Modal from "react-modal";
import {Redirect} from "react-router-dom";
import LocalizationService from "../../../translations/configLangContext";
import {i19n} from "../../../translations/i19n";

var zxcvbn = require('zxcvbn'); //cas library for psw validation
const xcvbnSettings = {
    allProgressBarClasses: 'progress-bar-danger progress-bar-warning progress-bar-success progress-bar-striped active',
    progressBarClass0: 'progress-bar-danger progress-bar-striped active',
    progressBarClass1: 'progress-bar-danger progress-bar-striped active',
    progressBarClass2: 'progress-bar-warning progress-bar-striped active',
    progressBarClass3: 'progress-bar-success',
    progressBarClass4: 'progress-bar-success',
    progressBarId: 'strengthProgressBar',
    passwordId: 'password',
}
const PASS_REGEX = new RegExp("^(?=.*[a-z|\u0430-\u044F|\u0451|\u03B1-\u03C9])(?=.*[A-Z|\u0410-\u042F|\u0401|\u0391-\u03A9])(?=.*[0-9])(?=.*[!@$%^&?*)(+=._-])(?=.{8,})");
const passwordStrengthI18n = {
    0: 'changePassword.strength.worst',
    1: 'changePassword.strength.bad',
    2: 'changePassword.strength.weak',
    3: 'changePassword.strength.good',
    4: 'changePassword.strength.strong',
};

const STEP_TYPE = {
    LOADING: 0,
    CHANGE_PASSWORD: 1,
    TOKEN_ERROR: 2,
    PASSWORD_CHANGED: 3,
};

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        if (props.location.state) {
            this.registered = props.location.state.status !== 'VR';  //set status retrieved by signup page
            this.signupLanguage = props.location.state.signupLanguage;
            this.change_pass_token = props.location.state.change_pass_token;
            this.landingPath = props.location.state.landingPath;
        }
        this.state = {
            lan: "",
            timer: 5,
            showPsw: false,
            showConfirmPsw: false,
        };
        this.tokenCheckFlag = false;
        this.step = -1;
        this.aidCode = null; //the aidCode returned after token checking
        this.user_mail = null;
        this.individualEid = null;
        this.professionalType = null;
        this.originalPersistService = null;
        this.aidStatus = null;
        this.professionalType = null;
        this.errorText = "";
        this.countdown = 0;
        this.tokenErrorType = "";
        this.redirectUrlToken = "";
        this.professionalType = "";
        this.errorTypeToi18nKeyLinker = {};
        Object.entries(ERROR_CODES.CHANGE_PASSWORD).forEach(([key, value]) =>{
            this.errorTypeToi18nKeyLinker[value] = `changePassword.errors.${key}`;
        });
        this.errorTypeToi18nKeyLinker['noTokenExpireDateFound'] = 'changePassword.errors.noTokenExpireDateFound';
        this.errorTypeToi18nKeyLinker['tokenMissing'] = 'changePassword.errors.tokenMissing';
        this.errorTypeToi18nKeyLinker['genericError'] = 'changePassword.errors.genericError';
        this.errorTypeToi18nKeyLinker['paramMissing'] = 'changePassword.errors.paramMissing';
        this.subLanForced = null;
        this.reqid = null;
        this.confirm = null;
        this.translateCompleted = false;
    }

    languageParsing() {

        if(this.signupLanguage) {
            // priority to language provided by SignupProfessional
            this.parsedLanguage = parseLanguage(this.signupLanguage.CULTURE_CODE);
        }
        else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL

        this.reqid = new URL(window.location.href).searchParams.get('reqid');
        this.confirm = new URL(window.location.href).searchParams.get('confirm');

        this.subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(this.subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(this.subLanForced);
        } else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        if(!this.parsedLanguage) {
            // language not recognized, fallback and redirect to default one
            let localizedPath = this.props.match.path.replace(":lan?", DEFAULT_LANGUAGE.LAN_CODE);
            return <Redirect to={localizedPath} />
        }
    }

    footerLoc() {

        let localized = i18n.t('footer');
        let localizedP = this.professionalType != "" ? i18n.t('footer_'+this.professionalType) : false;
        if(localizedP && i18n.exists('footer_'+this.professionalType)) {
            document.getElementById('footerText').innerHTML = localizedP;
        } else if (localized && i18n.exists('footer')) {
            document.getElementById('footerText').innerHTML = localized;
        }
    }

    timerStarter() {
        if(this.countdown === 0) this.countdown = setInterval(()=>{this.setState({timer: this.state.timer -1})}, 1000);
    }

    updateProgressBar() {
        let progressBar = document.getElementById(xcvbnSettings.progressBarId);
        let password = document.getElementById(xcvbnSettings.passwordId).value;
        if (password) {
            let result = zxcvbn(password, xcvbnSettings.userInputs);
            let scorePercentage = (result.score + 1) * 20;
            progressBar.style.width = scorePercentage + '%';

            xcvbnSettings.allProgressBarClasses.split(" ").forEach(cl => progressBar.classList.remove(cl));
            xcvbnSettings['progressBarClass' + result.score].split(" ").forEach(cl => progressBar.classList.add(cl));
            progressBar.textContent = i18n.t(passwordStrengthI18n[result.score]);
        } else {
            progressBar.style.width = '0%';
            xcvbnSettings.allProgressBarClasses.split(" ").forEach(cl => progressBar.classList.remove(cl));
            xcvbnSettings.progressBarClass0.split(" ").forEach(cl => progressBar.classList.add(cl));
            progressBar.textContent = '';
        }
    }

    getToken() {
        // pick token from querystring
        return this.queryStringParams.get("token");
    }

    getErrorTexts(error){
        let i18nKey = this.errorTypeToi18nKeyLinker[error];
        let text = !i18nKey || i18n.t(i18nKey).includes(i18nKey)
            ? i18n.t(this.errorTypeToi18nKeyLinker["genericError"])
            : i18n.t(i18nKey);
        let errorCodeText = Object.keys(ERROR_CODES.CHANGE_PASSWORD).includes(i18nKey.split('.').slice(-1)[0])
            ? `${i18n.t("changePassword.errors.shortIntro")}: ${error}`
            : "";
        return {i18nKey: i18nKey, text: text, errorCodeText: errorCodeText};
    }

    togglePassword(id){
        switch(id){
            case 'password':
                this.setState({showPsw: !this.state.showPsw});
                return;
            case 'passwordConfirmation':
                this.setState({showConfirmPsw: !this.state.showConfirmPsw});
                return;
        }

    }

    subIntro() {
        let localized = i18n.exists('changePassword.subIntro');
        if(this.props.is_doi_active && this.professionalType != null) {

            if(this.professionalType == "M" && this.originalPersistService != null && this.originalPersistService == "CRM_REGISTRATION" && this.aidStatus != null && this.aidStatus == "AID_ACTIVATION_PENDING") {
                if(i19n.t('changePassword.subIntro_doctor_consents_skip') !== "") return i19n.t('changePassword.subIntro_doctor_consents_skip')
                else if(i18n.exists('changePassword.subIntro_doctor_consents_skip')) return  i18n.t('changePassword.subIntro_doctor_consents_skip');
                else if(localized) return  i18n.t('changePassword.subIntro');
            }
            if(this.professionalType == "P" && this.originalPersistService != null && this.originalPersistService == "CRM_REGISTRATION" && this.aidStatus != null && this.aidStatus == "AID_ACTIVATION_PENDING") {
                if(i19n.t('changePassword.subIntro_pharmacist_consents_skip') !== "") return i19n.t('changePassword.subIntro_pharmacist_consents_skip')
                else if(i18n.exists('changePassword.subIntro_pharmacist_consents_skip')) return  i18n.t('changePassword.subIntro_pharmacist_consents_skip');
                else if(localized) return  i18n.t('changePassword.subIntro');
            }
            if(this.professionalType == "I" && this.originalPersistService != null && this.originalPersistService == "CRM_REGISTRATION" && this.aidStatus != null && this.aidStatus == "AID_ACTIVATION_PENDING") {
                if(i19n.t('changePassword.subIntro_nurse_consents_skip') !== "") return i19n.t('changePassword.subIntro_nurse_consents_skip')
                else if(localized) return  i18n.t('changePassword.subIntro');
            }

            if(this.professionalType == "M" && this.originalPersistService != null && this.originalPersistService == "API_REGISTRATION" && this.aidStatus != null && this.aidStatus == "AID_ACTIVATION_PENDING") {
                return i19n.t('changePassword.subIntro_doctor_consents_api') !== "" ? i19n.t('changePassword.subIntro_doctor_consents_api') : i18n.t('changePassword.subIntro');
            }
            if(this.professionalType == "P" && this.originalPersistService != null && this.originalPersistService == "API_REGISTRATION" && this.aidStatus != null && this.aidStatus == "AID_ACTIVATION_PENDING") {
                return i19n.t('changePassword.subIntro_pharmacist_consents_api') !== "" ? i19n.t('changePassword.subIntro_pharmacist_consents_api') : i18n.t('changePassword.subIntro');
            }
            if(this.professionalType == "I" && this.originalPersistService != null && this.originalPersistService == "API_REGISTRATION" && this.aidStatus != null && this.aidStatus == "AID_ACTIVATION_PENDING") {
                return i19n.t('changePassword.subIntro_nurse_consents_api') !== "" ? i19n.t('changePassword.subIntro_nurse_consents_api') : i18n.t('changePassword.subIntro');
            }

            if(this.professionalType == "U") {
                if(i19n.t('changePassword.subIntro_user') !== "") return i19n.t('changePassword.subIntro_user')
                else if(i18n.exists('changePassword.subIntro_user')) return  i18n.t('changePassword.subIntro_user');
                else if(localized) return  i18n.t('changePassword.subIntro');;
            }
            if(this.professionalType == "M") {
                if(i19n.t('changePassword.subIntro_doctor_consents_shown') !== "") return i19n.t('changePassword.subIntro_doctor_consents_shown')
                else if(i18n.exists('changePassword.subIntro_doctor_consents_shown')) return  i18n.t('changePassword.subIntro_doctor_consents_shown');
                else if(localized) return  i18n.t('changePassword.subIntro');;
            }
            if(this.professionalType == "P") {
                if(i19n.t('changePassword.subIntro_pharmacist_consents_shown') !== "") return i19n.t('changePassword.subIntro_pharmacist_consents_shown')
                else if(i18n.exists('changePassword.subIntro_pharmacist_consents_shown')) return  i18n.t('changePassword.subIntro_pharmacist_consents_shown');
                else if(localized) return  i18n.t('changePassword.subIntro');;
            }
            if(this.professionalType == "I") {
                if(i19n.t('changePassword.subIntro_nurse_consents_shown') !== "") return i19n.t('changePassword.subIntro_nurse_consents_shown')
                else if(localized) return  i18n.t('changePassword.subIntro');;
            }
        } else {
            if (localized) return  i18n.t('changePassword.subIntro');;
        }
    }

    renderChangePasswordStep(errors, touched, isSubmitting, isValid) {
        return (
            <Form>
                <div className="o-headers">
                    <h3 className="h h-1">
                        { i18n.t('changePassword.intro') }
                    </h3>
                </div>
                <div className="o-prose">
                    <p className="f-1" dangerouslySetInnerHTML={{__html: this.subIntro()}}></p>
                </div>
                {this.props.nationalid_required ?
                    <div className="form-group">
                        <label htmlFor="nationalId" className="c-label">{i18n.t('econsent.form.codiceFiscale')}</label>
                        <Field maxLength="254" name="nationalId" id="nationalId" type="text" className={'form-control c-input' + (errors.password && touched.password ? ' is-invalid' : '')} />
                        <ErrorMessage name="nationalId" component="div" className="invalid-feedback" />
                    </div>
                    : ""
                }

                {this.props.changepass_tmp_code_show_req ?
                    <div className="form-group">
                    <label htmlFor="temporaryCode" className="c-label">{i18n.t('changePassword.temporaryCode')}</label>
                    <Field maxLength="254" name="temporaryCode" id="temporaryCode" type="text" className={'form-control c-input' + (errors.password && touched.password ? ' is-invalid' : '')} />
                    <ErrorMessage name="temporaryCode" component="div" className="invalid-feedback" />
                    </div>
                    : ""
                }

                <div className="form-group">
                    <label htmlFor="password" className="c-label">{i18n.t('changePassword.passwordInput')}*</label>
                    <div class="row" style={{margin: 0}}>
                        <Field maxLength="254" name="password" id="password" type={this.state.showPsw ? 'text' : 'password'}
                            className={'form-control c-input' + (errors.password && touched.password ? ' is-invalid' : '')}
                            onKeyUp={this.updateProgressBar} />
                        <span class="row align-items-center" style={{marginLeft: "-50px"}}><i class={`fa ${this.state.showPsw ? "fa-eye-slash" : "fa-eye" }`}
                            title={i18n.t('forgotPassword.showHidePassword')}
                            onClick={() => this.togglePassword('password')}/></span>
                    </div>
                    <ErrorMessage name="password" style={{marginTop: "-20px"}} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group">
                    <label htmlFor="passwordConfirmation" className="c-label">{i18n.t('changePassword.passwordInputConfirm')}*</label>
                    <div class="row" style={{margin: 0}}>
                        <Field maxLength="254" name="passwordConfirmation" id="passwordConfirmation" type={this.state.showConfirmPsw ? 'text' : 'password'}
                            className={'form-control c-input' + (errors.passwordConfirmation && touched.passwordConfirmation ? ' is-invalid' : '')} />
                        <span class="row align-items-center" style={{marginLeft: "-50px"}}><i class={`fa ${this.state.showConfirmPsw ? "fa-eye-slash" : "fa-eye" }`}
                            title={i18n.t('forgotPassword.showHidePassword')}
                            onClick={() => this.togglePassword('passwordConfirmation')}/></span>
                    </div>
                    <ErrorMessage name="passwordConfirmation" style={{marginTop: "-20px"}} component="div" className="invalid-feedback" />
                </div>

                <div className="form-group" style={{marginTop: "6%"}}>
                    <div>
                        <span className="c-label">{i18n.t('changePassword.strength.title')}:</span>
                    </div>
                    <div className="progress">
                        <div id="strengthProgressBar" className="progress-bar"></div>
                    </div>
                </div>

                <div className="form-group mt-5" style={{marginTop: "10%"}}>
                    <button type="submit" className="c-button c-button-primary txt-uppercase" disabled={ !isValid || isSubmitting }>
                        {i18n.t('changePassword.formSubmit')}
                    </button>
                </div>
            </Form>
        )
    }

    renderErrorMessage(errorType) {
        let {text, errorCodeText} = this.getErrorTexts(errorType);
        return (
            <div>
                <h1 dangerouslySetInnerHTML={{__html: text}} style={{width: 'fit-content', display: 'block', margin: 'auto', textAlign: 'center' }}/>
                {errorCodeText.length > 0
                    ? <p style={{width: 'fit-content', display: 'block', margin: 'auto', marginTop: '10%' }}>{errorCodeText}</p>
                    : ""
                }
                { this.props.prefCenterUrlDetail && this.props.prefCenterUrlDetail.domain_url ?
                    <fieldset className="c-fieldset">
                        <div className="u-wrap-fields">
                            <div className="form-row">
                                <div className="form-group form-group-buttons">
                                    <div className="o-button o-button-full">
                                        <a className="c-button c-button-outline txt-uppercase" href={this.props.prefCenterUrlDetail.domain_url}>{i18n.t('changePassword.clickToLoginBtn')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    : ""
                }

            </div>
        )
    }

    componentDidUpdate(){
        if(this.state.timer === 0) {
            clearInterval(this.countdown);
            if(this.redirectUrlToken) window.location.replace(this.redirectUrlToken);
        }
    }

    renderStepManager(errors, touched, isSubmitting, isValid) {
        //saving querystring
        if(this.change_pass_token) {
            // if "change_pass_token" the token has been sent by signup form, to avoid race condition we'll wait 5 sec and redirect the user to the same page with token set into the URL params
            this.timerStarter();
            let currentUrl = new URL(window.location.href);
            currentUrl.searchParams.append(`token`, this.change_pass_token);
            this.redirectUrlToken = currentUrl;
            return ( <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/> );
        }
        this.queryStringParams = new URLSearchParams(this.props.location.search);
        let token = this.getToken();
        if(token && !this.tokenCheckFlag) {
            this.step = STEP_TYPE.LOADING;
            this.tokenCheck(token);
        } else if (!token) {
            this.tokenErrorType = "tokenMissing";
        }

        switch (this.step) {
            case STEP_TYPE.CHANGE_PASSWORD: return this.renderChangePasswordStep(errors, touched, isSubmitting, isValid);
            case STEP_TYPE.TOKEN_ERROR: return this.renderErrorMessage(this.tokenErrorType);
            case STEP_TYPE.PASSWORD_CHANGED: {
                if(!this.props.success_landing_path || this.props.success_landing_path.length <= 0) return this.renderErrorMessage('CP500');
                let successUrlToParse = new URL(window.location.origin);
                if(successUrlToParse.toString().endsWith("/")) successUrlToParse = successUrlToParse.toString().substring(0, successUrlToParse.toString().length - 1);
                successUrlToParse += this.props.success_landing_path;
                let successUrlFixed = new URL(successUrlToParse);
                if(this.aidCode) successUrlFixed.searchParams.append('aidcode', this.aidCode);
                if(this.subLanForced) successUrlFixed.searchParams.append('sublan', this.subLanForced);
                if(this.reqid) successUrlFixed.searchParams.append('reqid', this.reqid);
                if(this.confirm) successUrlFixed.searchParams.append('confirm', this.confirm);
                window.location.replace(successUrlFixed);
            }
            case STEP_TYPE.LOADING: {
                return ( <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/> );
            }
            case -1:
            default:
                return this.renderErrorMessage(this.tokenErrorType);

        }
    }

    tokenCheck(token) {
        fetch(this.props.serviceUrl, {
            credentials: 'same-origin',
            method: 'POST',
            // use token from querystring
            body: JSON.stringify({reqType: "tokenCheck", token: token}),
            headers: new Headers({ 'Content-Type': 'application/json' })
        })
        .then(response => { return response.json(); })
        .then(async data => {
            this.tokenCheckFlag = true;
            if ('error' in data && typeof data.error === 'string') {
                this.step = STEP_TYPE.TOKEN_ERROR;
                this.tokenErrorType = data.error ? data.error : "genericError";
            } else {
                this.step = STEP_TYPE.CHANGE_PASSWORD;
                this.aidCode = data.aid_code;
                this.user_mail = data.email;
                this.individualEid = data.individualEid;
                this.professionalType = data.professionalType;
                this.aidStatus = data.aidStatus;
                this.originalPersistService = data.originalPersistService;
                const langContext = LocalizationService.getInstance();
                await langContext.saveAndSynch(this.props.dalService, this.professionalType, this.parsedLanguage.CULTURE_CODE, "change_password");
            }
            this.forceUpdate();
            return data;
        })
        .catch(error => {
            console.error("Fetching error:", error);
            this.tokenCheckFlag = true;
            this.step = STEP_TYPE.TOKEN_ERROR;
            this.tokenErrorType = "genericError";
            this.forceUpdate();
        });
    }

    validationJsonBuilder() {
        let step = this.step;
        let validationJson = {};
        switch (step) {
            case STEP_TYPE.CHANGE_PASSWORD:
                validationJson["nationalId"] = this.props.nationalid_required
                    ? Yup.string().required(i18n.t('econsent.form.campoObbligatorio'))
                    : Yup.string().nullable();
                //no validation needed for temporaryCode
                if(this.props.changepass_tmp_code_show_req) validationJson["temporaryCode"] = Yup.string().required(i18n.t('econsent.form.campoObbligatorio'));
                validationJson['password'] = Yup.string()
                    .required(i18n.t('changePassword.passwordInputEmpty'))
                    .matches(PASS_REGEX, i18n.t('forgotPassword.pass_requirements'));
                validationJson['passwordConfirmation'] = Yup.string()
                    .required(i18n.t('changePassword.passwordInputConfirmEmpty'))
                    .oneOf([Yup.ref('password')], i18n.t('changePassword.passwordInputNoMatch'));

                break;
            default:
                return validationJson;
        }

        return validationJson;

    }
    async componentDidMount() {
        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.parsedLanguage.CULTURE_CODE, "change_password");

        this.setState({ translateCompleted: true});
    }
    render() {
        this.languageParsing();

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        this.footerLoc();
        return (
            <div class="col-lg-8 offset-lg-2">
                <Formik
                    initialValues={{
                        nationalId: "",
                        randomCode: "",
                        password: "",
                        passwordConfirmation: "",
                    }}
                    validateOnBlur={true}
                    validateOnChange={true}
                    validationSchema={ Yup.object().shape(this.validationJsonBuilder()) }
                    onSubmit={
                        (values, { setSubmitting } ) => {
                            if (this.step === STEP_TYPE.CHANGE_PASSWORD) {
                                values.reqType = "checkAndUpdate"; //check user info and update password
                                values.aid_code = this.aidCode;
                                values.email = this.user_mail;
                                values.individualEid = this.individualEid;
                                values.isDoiUrl = (this.reqid && this.confirm);     // if reqid & confirm param this URL is right before DOI form
                                fetch(this.props.serviceUrl, {
                                    credentials: 'same-origin',
                                    method: 'POST',
                                    body: JSON.stringify(values),
                                    headers: new Headers({ 'Content-Type': 'application/json' })
                                })
                                .then(response => { return response.json(); })
                                .then(data =>{
                                    if(('error' in data && typeof data.error === 'string') || (!('isValid' in data) || data.isValid === false)) {
                                        let error = data.error ? data.error: "genericError";
                                        if(error === ERROR_CODES.CHANGE_PASSWORD.CP_CHANGE_RETRY) {
                                            this.step = STEP_TYPE.TOKEN_ERROR;
                                            this.tokenErrorType = data.error ? data.error : "genericError";
                                            this.forceUpdate();
                                        } else {
                                            let {text, errorCodeText} = this.getErrorTexts(error);
                                            this.errorText = `${text} ${errorCodeText}`;
                                            this.setState({isErrorModalOpen: true});
                                        }
                                        return data;
                                    }
                                    this.step = STEP_TYPE.PASSWORD_CHANGED;
                                    setSubmitting(false);
                                    this.forceUpdate();
                                    return data;
                                })
                                .catch(error => {
                                    console.error("Fetching error:", error);
                                    this.errorText = i18n.t("forgotPassword.genericError");
                                    this.setState({ isErrorModalOpen: true });
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                                setSubmitting(false);
                            }
                        }
                    }
                >
                {({ errors, status, touched, isSubmitting, isValid }) => (
                    this.renderStepManager(errors, touched, isSubmitting, isValid)
                )}
            </Formik>
            <Modal
                isOpen={this.state.isErrorModalOpen}
                aria={{
                    labelledby: "heading",
                    describedby: "full_description"
                }}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                style= {{
                    content: {
                        maxWidth: "50%",
                        height: "fit-content",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        borderRadius: "25px",
                        padding: 0
                    }
                }} >
                <div className="modal-content">
                    <nav className="modal-title navbar-custom">
                        <strong className="tracking-wide">ERROR</strong>
                    </nav>
                    <div className="modal-body" style={{whiteSpace: 'pre-wrap'}}>
                        <p>{ this.errorText }</p>
                    </div>
                    <div className="modal-buttons" style={{marginBottom: "10px"}}>
                        <button className="btn btn-round btn-custom btn-outline-primary btn-pill modal-button" onClick={()=> this.setState({isErrorModalOpen: false, errorText: null})}>OK</button>
                    </div>
                </div>
            </Modal>
            </div>
        );
    }
}
