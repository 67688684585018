import React from 'react';
import {Helmet} from "react-helmet";
import {OBJECTS_PATH} from "../../../App";
import {parseLanguage} from "../../../translations/i18n";
import LocalizationService from "../../../translations/configLangContext";

const INTL_STEPS = {
    CHOICE_STEP: 0,
    STANDBY_STATUS: 2
}

export default class Intl extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeCountries: [],
            currentStep: INTL_STEPS.STANDBY_STATUS,
            userEmail: null,
            hasSublanguages: false,
        }
        this.countryOpts = [];
        this.isBrowserLanguage = false;
        this.translateCompleted = false;
    }

    async componentDidMount() {
        this.setState({ currentStep: INTL_STEPS.CHOICE_STEP });
        this.activeCountriesFetcher();

        this.parsedLanguage = parseLanguage(this.props.match.params.lan)
        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.parsedLanguage.CULTURE_CODE, "intl");

        this.setState({ translateCompleted: true});
    }

    activeCountriesFetcher() {
        fetch(this.props.dbSelectService, {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify({reqType: "intl_countries"}),
            headers: new Headers({'Content-Type': 'application/json'})
        }).then(response => {
            if (!response.ok) console.log("generic Error");
            else return response.json();
        }).then(data => {
            this.setState({activeCountries: data.data});
            window.setNiceSelect();
        });
    }

    countriesOptionsBuilder() {
        let elementsToRender = [];
        for (let country of this.state.activeCountries) {
            let elemToRender =
                <option value={this.redirectUrlBuilder(country.domain_url, country.intl_path_redirect)}>{country.intl_country_label}</option>
            if(country.language === window.navigator.language.toUpperCase()) {
                // if browser's language === to country language fetched from DB, set it as first choice
                elementsToRender.unshift(elemToRender);
                this.isBrowserLanguage = true;
            } else {
                elementsToRender.push(elemToRender);
            }
        }
        if (!this.isBrowserLanguage) {
            elementsToRender.unshift(
                <option value="" disabled selected>Select a country</option>
            );
        }
        return elementsToRender;
    }

    redirectUrlBuilder(domainUrl, pathDest) {
        let pathAttr = window.location.pathname.toString().replace(this.props.path_url, "") + window.location.search;
        if(pathDest.includes("?")) {
            pathAttr = pathAttr.replace("?", "&");
        }
        return domainUrl + pathDest + pathAttr;
    }

    countriesBuilder() {
        let activeCountriesLabelsFiltered = "";
        for(let country of this.state.activeCountries) {
            activeCountriesLabelsFiltered += country.intl_country_label + ", ";
        }
        activeCountriesLabelsFiltered = activeCountriesLabelsFiltered.substring(0, activeCountriesLabelsFiltered.lastIndexOf(", "));
        return activeCountriesLabelsFiltered;
    }

    render() {

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        if(this.state.currentStep === INTL_STEPS.STANDBY_STATUS ||
            (this.state.currentStep === INTL_STEPS.FIRST_CHOICE && this.state.activeCountries.length === 0)) {
            return (<>
                <Helmet><title>Intl</title></Helmet>
                <div class="o-headers">
                    <div class="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            </>);
        } else {
            this.countryOpts = this.countriesOptionsBuilder();
            return (
                <form method="get" >
                    <Helmet><title>Intl</title></Helmet>
                    <div className="o-headers">
                        <h1 className="h h-1">Select your country</h1>
                    </div>
                    <div className="o-prose">
                        <div className="e-content">
                            <p>Location selection allows us to deliver you the best services with respect to your localisation</p>
                        </div>
                    </div>

                    <div className="validation-summary-valid">
                        <ul>
                            <li style={{display:"none"}}></li>
                        </ul>
                    </div>{ /* /.validation-summary-valid */ }

                    <fieldset className="c-fieldset">
                        <div className="u-wrap-fields">
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="Country" className="c-label">Choose your country<span className="form-input-indicator"><span className="sr-only u-hidden">Campo Obbligatorio </span>*</span></label>
                                    <select className="c-input c-input-select" name="Country" id="Country" required >
                                        {this.countryOpts}
                                    </select>
                                </div>
                            </div>

                            {/* NOTE: per adesso sempre false perché ancora non c'è la logica per recupero sublanguages */}
                            {this.state.hasSublanguages ?
                                <div className="form-row">
                                    {/* TODO:
                                        1. recuperare languages
                                        2. condizionare div.form-row
                                        3. stampare le languages
                                        forse è megli rendere non utilizzabile la select piuttosto che rimuoverla del tutto?
                                    */}
                                    <div className="form-group">
                                        <label htmlFor="language" className="c-label">Select your language</label>
                                        <select className="c-input c-input-select" name="language" id="language" required>
                                            <option value="" selected disabled>Select a language</option>
                                            <option value="en">English</option>
                                            <option value="it">Italian</option>
                                        </select>
                                    </div>
                                </div>
                                : ""
                            }

                            <div className="form-row">
                                <div className="form-group form-group-buttons">
                                    <div className="o-button o-button-full">
                                        <input type="button" value="Continue" className="c-button c-button-primary txt-uppercase"
                                            onClick={() => {
                                                let redirectTo = document.getElementById("Country").value;
                                                if(redirectTo !== "") window.location = redirectTo;
                                            }}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </fieldset>
                    <div className="app__foot">
                        <div className="o-info">
                            <p>*Angelini iD system is reserved for Healthcare Professionals who carry out their clinical practice in the following countries: {this.countriesBuilder()}</p>
                        </div>
                    </div>
                </form>
            );
        }
    }
}
