import React from "react";
import i18n from "../../../translations/i18n";
import {parseLanguage, DEFAULT_LANGUAGE} from "../../../translations/i18n";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {OBJECTS_PATH} from '../../../App.js';
import LocalizationService from "../../../translations/configLangContext";
const LAMBDA_STATUS = { OK: "OK", ERROR: "ERROR", USER_NOT_FOUND: "USER_NOT_FOUND" }

export default class Remoteconsent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.subLanForced = null;
        this.translateCompleted = false;
    }

    async componentDidMount() {
        this.requestRemoteConsent();

        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, null, this.parsedLanguage.CULTURE_CODE, "remote_consent");

        this.setState({ translateCompleted: true});
    }

    footerLoc() {
        let localized = i18n.t('footer')
        if (localized) document.getElementById('footerText').innerHTML = localized;
    }

    requestRemoteConsent() {
        let currUrl = new URL(window.location.href);
        let params = new URLSearchParams(currUrl.search);
        let consentsParam = params.get("consents");
        let individualEidParam = params.get("eid");
        let aidCodeParam = params.get("aidcode");
        let consentsParsed = [];
        if(!consentsParam || !(individualEidParam || aidCodeParam)) {
            console.error(`ERROR: consents and at least individualEid or aidCode is required`);
            this.setState({ resStatus : LAMBDA_STATUS.ERROR });
            return;
        }
        if(consentsParam.startsWith("[") && consentsParam.endsWith("]")) {
            // consents is an array
            consentsParam = consentsParam.substring(1);
            consentsParam = consentsParam.substring(0, consentsParam.length-1);
            let splitParams = consentsParam.split(",");
            splitParams.forEach( consent => { consentsParsed.push(consent); });
        } else {
            // no array, pushing the only value into the array that will be sent to the cloud run
            consentsParsed.push(consentsParam);
        }
        let values = {
            consents: consentsParsed,
            aid_code: aidCodeParam,
            individualEid: individualEidParam,
        }
        let data = JSON.stringify(values, null, 2);

        fetch(this.props.serviceUrl, {
            credentials: 'same-origin',
            method: 'POST',
            body: data,
            headers: new Headers({ 'Content-Type': 'application/json' })
        })
            .then(response =>{
                if (!response.ok) {
                    this.setState({ resStatus : LAMBDA_STATUS.ERROR });
                    console.error("ERROR:", response);
                    return null;
                }
                return response;
            })
            .then(res => {
                if(!res) return null;
                this.setState({ resStatus : LAMBDA_STATUS.OK });
            })
            .catch(error => {
                console.error("ERROR:", error);
            });
    }

    subLanguageDefaultMan() {
        let defaultSubLan;
        for(let subLanInfo of this.props.subLanguagesInfo) {
            if(subLanInfo.isDefaultSubLan) defaultSubLan = subLanInfo
        }
        if(this.parsedLanguage && defaultSubLan.sub_culture && this.parsedLanguage.CULTURE_CODE !== defaultSubLan.sub_culture) this.subLanguageEventHandler(defaultSubLan.sub_culture)
    }

    subLanguageEventHandler(subLanKey) {
        // to avoid all reload-related issues we use 'sublan' param workaround
        let redirectUrl = new URL(window.location)
        redirectUrl.searchParams.set('sublan', subLanKey);
        window.location = redirectUrl;
    }

    render() {
        this.subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(this.subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(this.subLanForced);
        }
        else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        if(!this.parsedLanguage) {
            // language not recognized, fallback and redirect to default one
            let localizedPath = this.props.match.path.replace(":lan?", DEFAULT_LANGUAGE.LAN_CODE);
            return <Redirect to={localizedPath} />
        }

        if(this.props.subLanguagesInfo && !this.subLanForced) this.subLanguageDefaultMan();

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        this.footerLoc();

        switch (this.state.resStatus) {
            case LAMBDA_STATUS.ERROR: {
                return (
                    <div>
                        <img id="img" src={OBJECTS_PATH.PICTURES.GENERIC_ERROR_ICON} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }} alt="error icon"/>
                        <h3 className="text-center mb-4 mt-4">{i18n.t('econsent.serverError')}</h3>
                    </div>
                );
            }
            case LAMBDA_STATUS.OK: {
                return(
                    <div>
                        <h2 id="confirmMessage" dangerouslySetInnerHTML={{__html: i18n.t('unsubscribe.confirmMessage')}} />
                    </div>
                )
            }
            default: {
                return (
                    <div>
                        <Helmet>
                            <title>{i18n.t('unsubscribe.pageTitle')}</title>
                            <meta name="description" content={i18n.t('unsubscribe.pageTitle')} />
                        </Helmet>
                        <img id="img" alt="Loading" src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/>
                    </div>
                )
            }
        }
    }
};
