import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import {APP_URL} from '../App.js'

//******************HOW TO ADD NEW LANGUAGE******************
//      1. Create a common.json file
//      2. Import it with i18n code (e.g. import it from './it/common.json')
//      3. Insert new lang into SUPPORTED_LANG following the existing languages logic
//      4. Add the imported file in "resources" (e.g. it: { common: it })
//      5. To make language available, insert it into "whitelist" array
//************************************************************

import en from './en/common.json';
import it from './it/common.json';
import pt from './pt/common.json';
import pl from './pl/common.json';
import cs from './cs/common.json';
import ro from './ro/common.json';
import es from './es/common.json';
import bg from './bg/common.json';
import tr from './tr/common.json';
import hu from './hu/common.json';
import at from './at/common.json';
import sk from './sk/common.json';
import de from './de/common.json';
import el from './el/common.json';
import sv from './sv/common.json';
import da from './da/common.json';
import fi from './fi/common.json';
import no from './no/common.json';
import nl from './nl/common.json';
import en_nl from './en-NL/common.json';
import en_ie from './en-IE/common.json';
import de_ch from './de-CH/common.json';
import it_ch from './it-CH/common.json';
import en_ch from './en-CH/common.json';
import fr_ch from './fr-CH/common.json';
import en_nir from './en-NIR/common.json';
import fr_FR from './fr-FR/common.json';
import fr_BE from './fr-BE/common.json';
import nl_BE from './nl-BE/common.json';
import atlantis from './atlantis/common.json';
import {i19n} from "./i19n";


export const SUPPORTED_LANG = {
    ENGLISH: {
        LAN_CODE: "en",
        NATION_CODE: "GB",
        CULTURE_CODE: "en-GB"
    },
    ITALIAN: {
        LAN_CODE: "it",
        NATION_CODE: "IT",
        CULTURE_CODE: "it-IT"
    },
    CZECH: {
        LAN_CODE: "cs",
        NATION_CODE: "CZ",
        CULTURE_CODE: "cs-CZ"
    },
    POLISH: {
        LAN_CODE: "pl",
        NATION_CODE: "PL",
        CULTURE_CODE: "pl-PL"
    },
    PORTUGUESE: {
        LAN_CODE: "pt",
        NATION_CODE: "PT",
        CULTURE_CODE: "pt-PT"
    },
    ROMANIAN: {
        LAN_CODE: "ro",
        NATION_CODE: "RO",
        CULTURE_CODE: "ro-RO"
    },
    SPANISH: {
        LAN_CODE: "es",
        NATION_CODE: "ES",
        CULTURE_CODE: "es-ES"
    },
    BULGARIAN: {
        LAN_CODE: "bg",
        NATION_CODE: "BG",
        CULTURE_CODE: "bg-BG"
    },
    TURKISH: {
        LAN_CODE: "tr",
        NATION_CODE: "TR",
        CULTURE_CODE: "tr-TR"
    },
    HUNGARIAN: {
        LAN_CODE: "hu",
        NATION_CODE: "HU",
        CULTURE_CODE: "hu-HU"
    },
    AUSTRIA: {
        LAN_CODE: "at",
        NATION_CODE: "AT",
        CULTURE_CODE: "de-AT"
    },
    SLOVAKIA: {
        LAN_CODE: "sk",
        NATION_CODE: "SK",
        CULTURE_CODE: "sk-SK"
    },
    GERMAN: {
        LAN_CODE: "de",
        NATION_CODE: "DE",
        CULTURE_CODE: "de-DE"
    },
    GREEK: {
        LAN_CODE: "el",
        NATION_CODE: "GR",
        CULTURE_CODE: "el-GR"
    },
    SWEDISH: {
        LAN_CODE: "sv",
        NATION_CODE: "SE",
        CULTURE_CODE: "sv-SE"
    },
    DENMARK: {
        LAN_CODE: "da",
        NATION_CODE: "DK",
        CULTURE_CODE: "da-DK"
    },
    FINNISH: {
        LAN_CODE: "fi",
        NATION_CODE: "FI",
        CULTURE_CODE: "fi-FI"
    },
    NORWEGIAN: {
        LAN_CODE: "no",
        NATION_CODE: "NO",
        CULTURE_CODE: "no-NO"
    },
    DUTCH: {
        LAN_CODE: "nl",
        NATION_CODE: "NL",
        CULTURE_CODE: "nl-NL"
    },
    NETHERLAND_ENGLISH: {
        LAN_CODE: "en",
        NATION_CODE: "NL",
        CULTURE_CODE: "en-NL"
    },
    IRELAND_ENGLISH: {
        LAN_CODE: "en",
        NATION_CODE: "IE",
        CULTURE_CODE: "en-IE"
    },
    SWITZERLAND_GERMAN: {
        LAN_CODE: "de",
        NATION_CODE: "CH",
        CULTURE_CODE: "de-CH"
    },
    SWITZERLAND_ITALIAN: {
        LAN_CODE: "it",
        NATION_CODE: "CH",
        CULTURE_CODE: "it-CH"
    },
    SWITZERLAND_ENGLISH: {
        LAN_CODE: "en",
        NATION_CODE: "CH",
        CULTURE_CODE: "en-CH"
    },
    SWITZERLAND_FRENCH: {
        LAN_CODE: "fr",
        NATION_CODE: "CH",
        CULTURE_CODE: "fr-CH"
    },
    NORTHERN_IRELAND: {
        LAN_CODE: "en",
        NATION_CODE: "GB",
        CULTURE_CODE: "en-NIR",
    },
    BELGIUM_FRENCH: {
        LAN_CODE: "fr",
        NATION_CODE: "BE",
        CULTURE_CODE: "fr-BE",
    },
    BELGIUM_DUTCH: {
        LAN_CODE: "nl",
        NATION_CODE: "BE",
        CULTURE_CODE: "nl-BE",
    },
    FRANCE:{
        LAN_CODE: "fr",
        NATION_CODE: "FR",
        CULTURE_CODE: "fr-FR",
    },
    ATLANTIS:{
        LAN_CODE: "an",
        NATION_CODE: "IT",
        CULTURE_CODE: "ATLANTIS",
    }
};

const DbTranslations = (str, key) => {

    let translateString = i19n.t(key);
    if(translateString !== "") {
        return translateString
    }
    return str
}

export const DEFAULT_LANGUAGE = SUPPORTED_LANG.ITALIAN;

i18n.use({
    type: 'postProcessor',
    name: 'dbTranslation',
    process: function (value, key, options, translator) {
        return DbTranslations(value, key);
    }
}).use(Backend).use(LanguageDetector).use(initReactI18next)
    .init({
        fallbackLng: DEFAULT_LANGUAGE.CULTURE_CODE,
        //debug: true,
        defaultNS: "common",
        interpolation: { escapeValue: false, },
        lng: DEFAULT_LANGUAGE.CULTURE_CODE,
        resources: {
            enGB: { common: en },
            itIT: { common: it },
            ptPT: { common: pt },
            plPL: { common: pl },
            csCZ: { common: cs },
            roRO: { common: ro },
            esES: { common: es },
            bgBG: { common: bg },
            trTR: { common: tr },
            huHU: { common: hu },
            deAT: { common: at },
            skSK: { common: sk },
            deDE: { common: de },
            elGR: { common: el },
            svSE: { common: sv },
            daDK: { common: da },
            fiFI: { common: fi },
            noNO: { common: no },
            nlNL: {common: nl},
            enNL: {common: en_nl},
            enIE: {common: en_ie},
            enNIR: {common: en_nir},
            deCH: {common: de_ch},
            itCH: {common: it_ch},
            enCH: {common: en_ch},
            frCH: {common: fr_ch},
            frFR: {common: fr_FR},
            frBE: {common: fr_BE},
            nlBE: {common: nl_BE},
            ATLANTIS: {common: atlantis},
        },
        postProcess: ["dbTranslation"]
    });
export default i18n;

export function parseLanguage(lanToParse) {
    let parsedLan = DEFAULT_LANGUAGE;
    if(!lanToParse) return parsedLan;
    for (let supportedLang in SUPPORTED_LANG) {                                                 // iterate all SUPPORTED_LANG and check if lanToParse is listed
        const currentLan = SUPPORTED_LANG[supportedLang];
        if(currentLan.CULTURE_CODE && lanToParse === currentLan.CULTURE_CODE) {
            parsedLan = currentLan;
            break;
        }
    }
    let parsedCultureCode = parsedLan.CULTURE_CODE.replace("-", "");        // since dash are not allowed into resources obj, we must remove it to match the correct language
    i18n.changeLanguage(parsedCultureCode);                                                     // change language to parsed one
    return parsedLan;
}
