import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { App } from './App';
import './translations/i18n';

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
    document.getElementById('app')
);