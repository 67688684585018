class LocalizationService {

    static #instance;

    #proftype= null;
    #culture= null;
    #webpage= null;
    #languages = {};

    constructor() {
        if (LocalizationService.#instance) {
            throw new Error('Can not instantiate second singleton');
        }
        LocalizationService.#instance = this;
    }

    // getInstance should be static...
    //    to be able to call SingletonLogger.getInstance()
    static getInstance() {
        if (!LocalizationService.#instance) {
            LocalizationService.#instance = new LocalizationService();
        }
        return LocalizationService.#instance;
    }

    saveLocale(proftype, culture, webpage) {
        this.#proftype = proftype;
        this.#culture = culture;
        this.#webpage = webpage;
    }

    async saveAndSynch(url, proftype, culture, webpage) {

        this.#proftype = proftype;
        this.#culture = culture;
        this.#webpage = webpage;

        if(this.#languages.hasOwnProperty(culture) && this.#languages[culture].hasOwnProperty(proftype) && this.#languages[culture].hasOwnProperty(webpage)) {
            return this.#languages[culture][proftype][webpage];
        } else {
            await fetch(url, {
                credentials: 'same-origin',
                method: 'POST',
                // use token from querystring
                body: JSON.stringify({culture: culture, prof_type: proftype, web_page: webpage, platform: "REG"}),
                headers: new Headers({'Content-Type': 'application/json'})
            }).then(response => {
                if (!response.ok) { throw Error(response.statusText) }
                return response.json();
            }).then(resInfo => {
                if(Object.keys(resInfo).length !== 0) {
                    if(!this.#languages.hasOwnProperty(culture)) {
                        this.#languages = {[culture]: { [proftype]: { [webpage] : resInfo}}}
                    } else if(!this.#languages[culture].hasOwnProperty(proftype)) {
                        this.#languages[culture] = { [proftype] : { [webpage] : resInfo}}
                    } else if(!this.#languages[culture][proftype].hasOwnProperty(webpage)) {
                        this.#languages[culture][proftype] = { [webpage] : resInfo}
                    } else {
                        this.#languages[culture][proftype][webpage] = resInfo;
                    }
                } else if(resInfo.hasOwnProperty("message")) {

                    console.log(resInfo.message)
                } else {
                    console.log("empty translate")
                }
            });

        //     return fetch(url, {
        //         credentials: 'same-origin',
        //         method: 'POST',
        //         // use token from querystring
        //         body: JSON.stringify({culture: culture, prof_type: proftype, web_page: webpage}),
        //         headers: new Headers({'Content-Type': 'application/json'})
        //     })
        //         .then(response => {
        //             if (!response.ok) console.log("generic Error");
        //             else return response.json();
        //         })
        //         .then(data => {
        //             return data;
        //         })
        //         .catch(error => {
        //             console.error("Fetching error:", error);
        //         });

        }
    }

    // since #logs array is private, we need to create a getter
    get proftype() {
        return this.#proftype;
    }
    get culture() {
        return this.#culture;
    }
    get webpage() {
        return this.#webpage;
    }

    get currentTranslateObject() {
        if(this.#languages.hasOwnProperty(this.#culture) && this.#languages[this.culture].hasOwnProperty(this.#proftype) && this.#languages[this.#culture][this.#proftype].hasOwnProperty(this.#webpage)) {
            return this.#languages[this.#culture][this.#proftype][this.#webpage]
        }
        return {}
    }
}

export default LocalizationService;
