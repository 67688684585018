import LocalizationService from "./configLangContext";

export class i19n {

   static t(key) {
      const langContext = LocalizationService.getInstance();
      var translateObj = langContext.currentTranslateObject
      if(translateObj.hasOwnProperty(key)) {
         return translateObj[key]
      }
      return ""
   }
}


